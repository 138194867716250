import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import { isProduction } from 'apollo-utilities';
import * as constants from '../constants';
import { isDev } from '../constants';
import {
  LoginWithRefreshTokenDocument,
  LoginWithRefreshTokenMutation,
  LoginWithRefreshTokenMutationVariables,
} from '../generated/models';
import auth from '../utils/auth';

const API_URL = constants.isDev ? constants.DEV_URL : constants.PROD_URL;

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

const httpLink = createUploadLink({
  headers: {
    'keep-alive': 'true',
  },
  uri: API_URL,
});
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const tryToReconnect = () => {
      const token = auth.getRefreshToken();

      if (!token) {
        return;
      }

      // eslint-disable-next-line no-console
      console.warn('Trying to reconnect user');

      // Tokens no longer needed, remove cookies
      auth.removeAuthTokens();

      return new Observable(observer => {
        apolloClient
          .mutate<
            LoginWithRefreshTokenMutation,
            LoginWithRefreshTokenMutationVariables
          >({
            mutation: LoginWithRefreshTokenDocument,
            variables: { token },
          })
          .then(
            ({
              data: {
                loginWithRefreshToken: { accessToken, refreshToken },
              },
            }) => {
              auth.saveAuthTokens(accessToken, refreshToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: `Bearer ${accessToken}` || null,
                },
              }));
              // eslint-disable-next-line no-console
              console.warn('Reconnect successfully');
            },
          )
          .then(() => {
            const subscriber = {
              complete: observer.complete.bind(observer),
              errror: observer.error.bind(observer),
              next: observer.next.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            observer.error(error);
          });
      });
    };

    const goHome = () => {
      // eslint-disable-next-line no-console
      console.warn('Redirecting to home page');
    };

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            return tryToReconnect();
          case 'UNAUTHORIZED':
            goHome();
            break;
          case 'INTERNAL_SERVER_ERROR':
            if (isDev) {
              alert(err.message);
            } else {
              // eslint-disable-next-line no-console
              console.error(err.message);
            }
          default:
            // eslint-disable-next-line no-console
            console.error(`Vertrax Server Error ${err.extensions.code}`);
        }
      }
    }

    if (networkError) {
      return tryToReconnect();
    }
  },
);

const authLink = setContext((_, { headers }) => {
  const token = 'not-implemented';
  const accessToken = auth.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'x-api-key': `${token}`,
    },
  };
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: isProduction ? false : true,
  link,
});

export default apolloClient;
