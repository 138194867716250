export const isDev = process.env.NODE_ENV === 'development';
export const DEV_URL = process.env.API_DEV_URL;
export const PROD_URL = process.env.API_PROD_URL;

export const MAPBOX_TOKEN = process.env.MAPBOX_API_KEY;

export default {
  DEV_URL,
  MAPBOX_TOKEN,
  PROD_URL,
  isDev,
};
